import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tray-theme-editor__content tray-theme-editor__display" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resolutions, (resolution, index) => {
      return (_openBlock(), _createBlock(_component_Icon, {
        key: index,
        class: "tray-theme-editor__icon",
        name: resolution.icon,
        prefix: "fa",
        onClick: ($event: any) => (_ctx.changeResolution(resolution))
      }, null, 8, ["name", "onClick"]))
    }), 128))
  ]))
}