
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';
import { mapGetters, mapMutations } from 'vuex';
import { Mutations } from '@/models/store/theme-editor';

export default defineComponent({
  components: {
    Icon,
  },
  computed: {
    ...mapGetters('themeEditor', {
      resolutions: 'previewResolutions',
      activeResolution: 'previewSelectedResolution',
    }),
  },
  methods: {
    ...mapMutations('themeEditor', {
      setResolution: Mutations.PREVIEW_SET_RESOLUTION,
    }),
    changeResolution(resolution: any): void {
      this.setResolution(resolution);
    },
  },
});
